@import "./variables.scss";


.mainContainer {
  width: ($size * 5) + ($gap * 5);
  margin: 0px auto;
}
.box {
  font-size: 2em;
  font-weight: bold;
  width: $size;
  height: $size;
  background-color: #808080;
  border: solid 2px #c5c2c2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  gap: $gap;
  margin-bottom: $gap;
  text-transform: uppercase;
}

.logoReligada{
  width: 300px !important;
  display: block !important;
  margin: 0 auto !important;
}

.logoReligada:hover{
  opacity: .7;
}

.marginKeyboard{
  margin-top: 50px;
  margin-bottom: 25px;  
}

.homeButton {
  background-color: #cc5c1c; 
  min-width: 125px !important;
  border: none;
  color: white;
  font-weight: bold;
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 10px 10px;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.homeButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #df824c;
}

.deactivatedButton{
  background-color: #8d7a70; 
  min-width: 125px !important;
  border: none;
  color: white;
  font-weight: bold;
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 10px 10px;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.btnToolbar {
  display: flex;
  justify-content: center;
}

.menu{ 
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondItem{
  margin-left: 100px;
}

.titles{
  text-align: center;
  color: white;
}

h1{
  font-size: 1.5em;
}

.child{
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 600px) {

  .mainContainer{
    width: 100%;
    margin-top: 30px;
  }

  .row .row{
    display: flex;
    justify-content: center;
    background-color: red;
  }

  .titles{
    margin-top: 150px !important;
  }

  .box {
    font-size: 1em;
    width: 35px;
    height: 35px;
    background-color: red;
  }

  h1{
    font-size: 1em;
  }

  .logoReligada{
    width: 300px !important;
  }

  .homeButton {
    min-width: 125px !important;
    font-size: 14px;
    padding:14px;
  }

  .btnToolbar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10px;
  }

  .firstItem{
    margin: 0px;
  }

  .secondItem{
    display: flex;
    justify-content: center;
  }

  .child{
    padding: 0px;
    margin: 0px;
  }

}