@import "./variables.scss";

@mixin box {
  font-size: 2em;
  font-weight: bold;
  width: $size;
  height: $size;
  @media only screen and (max-width: 600px) {
    font-size: 1.5em;
    width: $sizeMobile;
    height: $sizeMobile;
  }
  background-color: #808080;
  border: solid 2px #c5c2c2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.correct {
  @include box;
  background-color: #4caf50;
  border: #c5c2c2 solid 2px;
  color: black;
}

.present {
  @include box;
  background-color: #e5f321;
  border: #c5c2c2 solid 2px;
  color: black;
}

.absent {
  @include box;
  background-color: #aaa;
  border: #aaa solid 2px;
  color: black;
}

.empty {
  @include box;
}
.edit {
  @include box;
}

