@import "./variables.scss";

@mixin keybase {
  background-color: #333;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keyboardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 570px;
  margin: 0 auto;
  gap: 5px;
}
.key {
  @include keybase;
  width: 50px;
  height: $sizeKey;
}
.emptyKey {
  width: 25px;
  height: $sizeKey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enterKey {
  @include keybase;
  width: 80px;
  height: $sizeKey;
}

.deleteKey {
  @include keybase;
  width: 80px;
  height: $sizeKey;
}

.correct {
  background-color: #4caf50;
  border: #4caf50 solid 2px;
  color: black;
}

.present {
  background-color: #e5f321;
  border: #e5f321 solid 2px;
  color: black;
}

.absent {
  background-color: #aaa;
  border: #aaa solid 2px;
  color: black;
}

.keyboardRow{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: space-between;
  gap: $gap;
}

@media only screen and (max-width: 600px) {
  .key {
    width: 28px;
    height: $sizeKeyMobile;
  }

  .emptyKey {
    width: 28px;
    height: $sizeKeyMobile;

  }
  
  .enterKey {
    width: 57px;
    font-size: 9px;
    height: $sizeKeyMobile;
  }
  
  .deleteKey {
    width: 57px;
    font-size: 9px;
    height: $sizeKeyMobile;
  }

  .keyboardContainer { 
    width: 100%;
    /*margin: 0 auto;
    display: flex;*/
    display: flex;
    justify-content: center;
  }

  .keyboardRow{
    display: flex;
    justify-content: center;
  }

/*   .keyboardRow{
    display: flex;
    justify-content: center;
  } */
}