.modalViewContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  background-color: white;
  border-radius: 25px;
  padding: 20px;
  padding-bottom: 50px;
  width: 500px;
  min-height: 300px;
  height: 800px;
  @media only screen and (max-width: 600px) {
    height: 500px;
  }
  overflow: auto; 

  h2 {
    text-align: center;
    font-size: 36px;
  }
}

.puzzle {
  width: 200px;
  margin: 0 auto;
  font-size: 30px;
}

.puzzleWord {
  display: flex;
}


.closeButton {
  background-color: #e86161; 
  border: none;
  color: white;
  padding: 20px 50px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.closeButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #d85959;
}

.centerButton{
  margin-top: 50px;
  text-align: center;
}

.paragraph{

  size: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}