@import "./variables.scss";
.row {
  display: flex;
  gap: $gap;
  margin-bottom: $gap;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}

